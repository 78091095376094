import Layout from '../layout/index.vue'

export default [
	{
		path: '/customer_grade',
		name: 'Customer_grade',
		component: Layout,
		redirect: 'customer_grade/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/customer_grade/index.vue')
			},
			{
				path: 'nature',
				component: () => import('../views/customer_grade/nature.vue')
			},

		]
	}, {
		path: '/customer_group',
		name: 'Customer_group',
		component: Layout,
		redirect: 'customer_group/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/customer_group/index.vue')
			}
		]
	},
	
	
	{
		path: '/customer',
		name: 'Customer',
		component: Layout,
		redirect: 'customer/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/customer/index.vue')
			},
			{
				path: 'index_edit',
				component: () => import('../views/customer/index/edit.vue')
			},
			{
				path: 'excavate_statistics',
				component: () => import('../views/customer/excavate_statistics.vue')
			},
			{
				path: 'excavate_statistics_details',
				meta: {
					select_path: 'customer/excavate_statistics'
				},
				component: () => import('../views/customer/excavate_statistics/details.vue')
			},
			{
				path: 'op_log',
				component: () => import('../views/customer/op_log/index.vue')
			}
		]
	},
	{
		path: '/line',
		name: 'Line',
		component: Layout,
		redirect: 'line/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/line/index.vue')
			}
		]
	},
	{
		path: '/imitation',
		name: 'Imitation',
		component: Layout,
		redirect: 'imitation/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/imitation/index.vue')
			},
			{
				path: 'report',
				component: () => import('../views/imitation/report.vue')
			}
		]
	},
	
	{
		path: '/trip_visit',
		name: 'TripVisit',
		component: Layout,
		redirect: 'trip_visit/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/trip_visit/index.vue')
			},
		]
	},
];