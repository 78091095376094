import Layout from '../layout/index.vue'

export default [
	{
		path: '/order',
		name: 'Order',
		component: Layout,
		redirect: 'order/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/order/index.vue')
			},
            {
            	path: 'target',
            	component: () => import('../views/order/target.vue')
            },
            {
            	path: 'order_details',
            	component: () => import('../views/order/order_details.vue')
            },
            {
            	path: 'order_edit',
            	component: () => import('../views/order/order_edit.vue')
            },
            // new_index
		]
	},
	{
		path: '/stock',
		name: 'Stock',
		component: Layout,
		redirect: 'stock/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/stock/index.vue')
			},
			{
				path: 'compete',
				component: () => import('../views/stock/compete.vue')
			},
		]
	},
	{
		path: '/warning',
		name: 'Warning',
		component: Layout,
		redirect: 'warning/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/warning/index.vue')
			}
		]
	},
	{
		path: '/advance',
		name: 'Advance',
		component: Layout,
		redirect: 'advance/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/advance/index.vue')
			}
		]
	},

	
];